import {mapGetters, mapMutations, mapActions} from "vuex";

//sections

export default {
    name: "game",
    components: {},
    data() {
        return {
            checked: false,
            donated: false
        }
    },
    beforeMount() {
        this.checked = this.isDonated
    },
    computed: {
        ...mapGetters({
            isDonated: 'setting/checkedDonate',
            userInfo: 'game/userInfo',
        })
    },

    methods: {
        ...mapMutations({
            checkedDonate: 'setting/SET_CHECKED_DONATE'
        }),
        ...mapActions({
            makeDonate: 'game/MAKE_DONATE'
        }),
        check() {
            this.checked = !this.checked
            this.makeDonate()
            this.checkedDonate(this.checked)
        }
    }
}
